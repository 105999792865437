
import { Options, Vue, prop } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import { Package } from '@/services/packages/PackageService'

class Props {
  package?: Package
  round?: string
}

@Options({
  name: 'Package-tag',
  components: {
    Icon
  }
})

/**
 * @param {string} package - Bronze, Silver, Gold, Platinum
 * @param {attribute=} round - Package Tag without Label
 * @description {string} template#label
 */
export default class PackageTag extends Vue.with(Props) { }
