
import { Options, Vue } from 'vue-class-component'
import Separator from '@/02-molecules/Separator.vue'
import Button from '@/01-atoms/Button.vue'
import Icon from '@/01-atoms/Icon.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Notification from '@/02-molecules/Notification.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, NotificationsServiceKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import getTimeAgo from '@/helpers/get-time-ago'

@Options({
  name: 'Notifications',
  components: {
    LoadingDots,
    Separator,
    Button,
    Icon,
    IconButton,
    Notification
  }
})
export default class Notifications extends Vue {
  currentPage = 1
  itemPerPage = 20
  isLoading = true
  notificationsService = inject(NotificationsServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  mounted () {
    this.notificationsService?.loadNotifications(this.currentPage, this.itemPerPage)
        .then(() => {
          this.isLoading = false
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  sortedNotifications (index: number) {
    const newArray = [[], [], [], [], []]
    this.notificationsService?.notifications.forEach((notification) => {
      const index = getTimeAgo(notification.createdAt)!.index
      newArray[index].push(notification)
    })
    return newArray[index]
  }

  clickLoadOlder () {
    this.isLoading = true
    this.currentPage++
    this.notificationsService?.loadOlderNotifications(this.currentPage, this.itemPerPage)
        .then(() => {
          this.isLoading = false
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }
}
