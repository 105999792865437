<template>
  <div :class="`package-tag package-tag--package_${package.icon} ${round === '' && 'is-round'}`">
    <Icon class="package-tag__icon" :icon="`package_${package.icon}`" />
    <div :class="`package-tag__label`">
      <slot name="label" v-if="$slots.label">#label</slot>
      <span v-else>{{ package.name }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import { Package } from '@/services/packages/PackageService'

class Props {
  package?: Package
  round?: string
}

@Options({
  name: 'Package-tag',
  components: {
    Icon
  }
})

/**
 * @param {string} package - Bronze, Silver, Gold, Platinum
 * @param {attribute=} round - Package Tag without Label
 * @description {string} template#label
 */
export default class PackageTag extends Vue.with(Props) { }
</script>
<style lang="scss" scoped>
.package-tag {
  @include brandon-grotesque-bold;
  text-transform: uppercase;
  border-radius: 30px;
  color: $grey-dark;
  display: inline-flex;
  align-items: center;
  padding: 5px 17px 5px 5px;

  &.is-round {
    padding: 5px;
    margin-right: 15px;
  }

  &--package_bronze {
    background-color: $package-bronze-bg;

    .package-tag__icon {
      color: $package-bronze;
    }
  }

  &--package_silver {
    background-color: $package-silver-bg;

    .package-tag__icon {
      color: $package-silver;
    }
  }

  &--package_gold {
    background-color: $package-gold-bg;

    .package-tag__icon {
      color: $package-gold;
    }
  }

  &--package_platinum {
    background-color: $package-platinum-bg;

    .package-tag__icon {
      color: $package-platinum;
    }
  }
}

.package-tag__icon {
  height: 32px;
  position: relative;
  width: 32px;
  z-index: 1;

  &::before {
    content: '';
    background-color: $white;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    z-index: -1;
  }
}

.package-tag__label {
  margin-left: 5px;

  .is-round & {
    display: none;
  }
}
</style>
