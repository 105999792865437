import checkMediaQuery from '@/helpers/media-query'
import variables from '@/scss/js-variables.scss'

/**
 *
 * @param {HTMLElement}
 */

const calculateContainerHeight = (element: any, offset = 0) => {
  if (checkMediaQuery(`(max-width: ${variables.navigationBreakpoint})`)) {
    const chatPositionX = element.getBoundingClientRect().y
    const screenHeight = window.innerHeight
    const elementHeight = screenHeight - chatPositionX - offset
    return `height:${elementHeight}px;`
  }
}

export default calculateContainerHeight
