
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  moreMenuIcon!: string
}

@Options({
  name: 'Responsive-navigation',
  components: {
    Icon
  },
  emits: ['toggle-navigation-overlay']
})
export default class ResponsiveNavigation extends Vue.with(Props) {
  closeNavigationOverlay () {
    if (this.moreMenuIcon === 'x') {
      this.toggleNavigationOverlay()
    }
  }

  toggleNavigationOverlay () {
    this.$emit('toggle-navigation-overlay')
  }
}
