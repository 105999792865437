<template>
  <div class="mb-l">
    <h1 class="display-1">{{ $t('notifications.title') }}</h1>
  </div>
  <div class="notifications mb-xl">
    <ul class="notifications__list">
      <li v-if="sortedNotifications(4).length" class="notifications__list__divider tt-uppercase fs-12"><span class="notifications__list__divider__label">{{ $t('global.latest') }}</span></li>
      <li v-for="item in sortedNotifications(4)" v-bind:key="item.title">
        <Notification :notification="item" />
      </li>
      <li v-if="sortedNotifications(3).length" class="notifications__list__divider tt-uppercase fs-12"><span class="notifications__list__divider__label">{{ $t('global.last-week') }}</span></li>
      <li v-for="item in sortedNotifications(3)" v-bind:key="item.title">
        <Notification :notification="item" />
      </li>
      <li v-if="sortedNotifications(2).length" class="notifications__list__divider tt-uppercase fs-12"><span class="notifications__list__divider__label">{{ $t('global.2-weeks-ago') }}</span></li>
      <li v-for="item in sortedNotifications(2)" v-bind:key="item.title">
        <Notification :notification="item" />
      </li>
      <li v-if="sortedNotifications(1).length" class="notifications__list__divider tt-uppercase fs-12"><span class="notifications__list__divider__label">{{ $t('global.month-ago') }}</span></li>
      <li v-for="item in sortedNotifications(1)" v-bind:key="item.title">
        <Notification :notification="item" />
      </li>
      <li v-if="sortedNotifications(0).length" class="notifications__list__divider tt-uppercase fs-12"><span class="notifications__list__divider__label">{{ $t('global.year-ago') }}</span></li>
      <li v-for="item in sortedNotifications(0)" v-bind:key="item.title">
        <Notification :notification="item" />
      </li>
    </ul>
  </div>
  <div class="ta-center mb-l">
    <LoadingDots v-if="isLoading" />
    <Button @click="clickLoadOlder" v-else>{{ $t('global.load-more-button') }}</Button>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Separator from '@/02-molecules/Separator.vue'
import Button from '@/01-atoms/Button.vue'
import Icon from '@/01-atoms/Icon.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Notification from '@/02-molecules/Notification.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, NotificationsServiceKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import getTimeAgo from '@/helpers/get-time-ago'

@Options({
  name: 'Notifications',
  components: {
    LoadingDots,
    Separator,
    Button,
    Icon,
    IconButton,
    Notification
  }
})
export default class Notifications extends Vue {
  currentPage = 1
  itemPerPage = 20
  isLoading = true
  notificationsService = inject(NotificationsServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  mounted () {
    this.notificationsService?.loadNotifications(this.currentPage, this.itemPerPage)
        .then(() => {
          this.isLoading = false
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  sortedNotifications (index: number) {
    const newArray = [[], [], [], [], []]
    this.notificationsService?.notifications.forEach((notification) => {
      const index = getTimeAgo(notification.createdAt)!.index
      newArray[index].push(notification)
    })
    return newArray[index]
  }

  clickLoadOlder () {
    this.isLoading = true
    this.currentPage++
    this.notificationsService?.loadOlderNotifications(this.currentPage, this.itemPerPage)
        .then(() => {
          this.isLoading = false
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }
}
</script>
<style lang="scss" scoped>
.notifications__list {
  list-style: none;
  padding-left: 0;
}

.notifications__list__divider {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 25px;

  &::after {
    content: '';
    display: inline-block;
    background-color: $blue-dark-10;
    height: 2px;
    margin-left: 10px;
    position: relative;
    width: 100%;
  }
}

.notifications__list__divider__label {
  flex-shrink: 0;
}

.notifications__separator {
  display: block;
  background: $blue-dark-10;
  height: 2px;
  margin: 15px 0;
  width: 100%;
}
</style>
