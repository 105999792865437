<template>
  <nav :class="'navigation ' + (showResponsiveNavigationOverlay && 'has-overlay')" ref="navigation">
    <button class="blue-dark" @click="switchLanguage" style="position:fixed;left:5px;top:5px;z-index:9999;">switch to {{ switchTo }}</button>
    <div class="navigation__main">
      <router-link active-class="is-active" :to="{name: 'Dashboard'}">
        <Logo class="navigation__logo" @click="closeNavigationOverlay"></Logo>
      </router-link>
      <router-link active-class="is-active" :to="{name: 'Dashboard'}">
        <span @click="closeNavigationOverlay" class="fs-20 mb-l navigation__item">
          <Icon class="navigation__item__icon mr-m" icon="home"></Icon> {{ $t('navigation.home') }}
        </span>
      </router-link>
      <router-link active-class="is-active" :to="{name: 'Inquiries'}">
        <span @click="closeNavigationOverlay" class="fs-20 mb-l navigation__item">
          <Icon :notifications="getUnseens(notificationsService.notifications, 'inquiries')"
                class="navigation__item__icon mr-m"
                icon="upload" />
          {{ $t('navigation.inquiries') }}
        </span>
      </router-link>
      <router-link active-class="is-active" :to="{name: 'Family'}">
        <span @click="closeNavigationOverlay" class="fs-20 mb-l navigation__item">
          <Icon class="navigation__item__icon mr-m" icon="users"></Icon> {{ $t('navigation.family') }}
        </span>
      </router-link>
      <router-link active-class="is-active" :to="{name: 'Documents'}">
        <span @click="closeNavigationOverlay" class="fs-20 mb-l navigation__item">
          <Icon :notifications="getUnseens(notificationsService.notifications, 'documents')"
                class="navigation__item__icon mr-m"
                icon="file-text" />
          {{ $t('navigation.documents') }}
        </span>
      </router-link>
      <IconButton style="margin-top:30px;margin-bottom: 15px" to="/design-system" icon="square" lowercase>Design system</IconButton>
    </div>
    <div class="navigation__more">
      <router-link active-class="is-active" :to="{name: 'Settings'}">
        <span @click="closeNavigationOverlay" class="fs-20 mb-l navigation__item">
          <Icon class="navigation__item__icon mr-m" icon="settings"></Icon> {{ $t('navigation.settings') }}
        </span>
      </router-link>
      <router-link active-class="is-active" :to="{name: 'Support'}">
        <span @click="closeNavigationOverlay" class="fs-20 mb-l navigation__item">
          <Icon class="navigation__item__icon mr-m" icon="message-circle"></Icon> {{ $t('navigation.support') }}
        </span>
      </router-link>
      <IconButton @click="clickLogout" icon="log-out" lowercase>
        {{ $t('navigation.logout') }}
      </IconButton>
    </div>
  </nav>
  <ResponsiveNavigation @toggle-navigation-overlay="toggleNavigationOverlay" :moreMenuIcon="moreMenuIcon" />
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Logo from '@/assets/logo-minimal.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Icon from '@/01-atoms/Icon.vue'
import ResponsiveNavigation from '@/03-organisms/Responsive-navigation.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { logout } from '@/api/user/user-api'
import { ErrorHandlerKey, NotificationsServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'

@Options({
  name: 'Navigation',
  components: {
    Logo,
    IconButton,
    Icon,
    ResponsiveNavigation
  }
})

export default class Navigation extends Vue {
  showResponsiveNavigationOverlay = false
  moreMenuIcon = 'menu'

  //for translation purposes only
  switchTo = ''

  notificationsService = inject(NotificationsServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  beforeMount () {
    this.notificationsService?.loadNotifications(1, 20)
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })

    if (this.$i18n.locale === 'en') {
      this.switchTo = 'Ids'
    } else {
      this.switchTo = 'english'
    }
  }

  switchLanguage () {
    if (this.$i18n.locale === 'en') {
      this.$i18n.locale = 'tlh'
      this.switchTo = 'english'
    } else {
      this.$i18n.locale = 'en'
      this.switchTo = 'ids'
    }
    this.setHtmlLocale()
  }

  setHtmlLocale () {
    const htmlElement = window['document'].querySelector('html')!
    htmlElement.setAttribute('lang', this.$i18n.locale)
  }
  //end for translation purposes only

  toggleNavigationOverlay () {
    if (this.showResponsiveNavigationOverlay) {
      this.closeNavigationOverlay()
    } else {
      this.openNavigationOverlay()
    }
  }

  openNavigationOverlay () {
    this.moreMenuIcon = 'x'
    disableBodyScroll(this.$refs.navigation)
    this.showResponsiveNavigationOverlay = true
  }

  closeNavigationOverlay () {
    this.moreMenuIcon = 'menu'
    enableBodyScroll(this.$refs.navigation)
    this.showResponsiveNavigationOverlay = false
  }

  clickLogout () {
    this.closeNavigationOverlay()
    logout()
  }

  getUnseens (notifications: any, type: string) {
    let number = 0
    this.notificationsService?.notifications.forEach((notification) => {
      if (!notification.isViewed && notification.type === type) {
        number += 1
      }
    })
    return number
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  background-color: white;
  box-shadow: -50px 0 50px white, 1px -2px 10px -2px transparentize($grey-dark, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  height: 100vh;
  max-width: 274px;
  padding: 70px 3%;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 20%;

  @include breakpoint($responsive-navigation-breakpoint down) {
    display: none;
  }

  &.has-overlay {
    @include z-index('responsive-menu-overlay');
    display: block;
    max-width: 100%;
    padding: 45px 20px;
    position: fixed;
    width: 100%;
  }
}

.navigation__logo {
  margin-bottom: 67px;
  width: 100%;

  .has-overlay & {
    margin-bottom: 40px;
  }
}

.navigation__item {
  @include focus;
  color: $blue-dark;
  display: flex;
  align-items: center;
  height: 26px;

  .is-active > & {
    text-shadow: 1px 0px 0px $blue-dark;
  }
}

.navigation__item__icon {
  height: 26px;
  flex-shrink: 0;
  width: 26px;

  .is-active > .navigation__item > & {
    background-color: $blue-dark;
    border-radius: 50%;
    color: white;
    padding: 0 4px;
  }
}
</style>
