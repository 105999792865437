<template>
  <div class="topbar">
    <div class="d-f">
      <router-link :to="{name: 'Packages'}" class="topbar__responsive-package">
        <PackageTag :package="packageService.currentPackage" round></PackageTag>
      </router-link>
      <div class="mr-xl">
        <span class="fs-24 fw-700 d-b mb-s">
          <span class="topbar__greeting">{{ $t('topbar.greeting') }}</span>
          {{ membersService.currentMember.firstName}}
        </span>
        <span class="fs-16 grey-dark">{{ membersService.currentMember.matricule }}</span>
      </div>
      <div class="d-f topbar__package">
        <router-link :to="{name: 'Package'}">
          <PackageTag class="mr-m" :package="packageService.currentPackage" />
        </router-link>
        <router-link :to="{name: 'Packages'}" class="grey-dark fw-700 tt-uppercase">{{ $t('topbar.upgrade') }}</router-link>
      </div>
    </div>
    <div class="d-f">
      <IconButton class="mr-l topbar__new-inquiry" :to="{name: 'New inquiry'}" icon="plus-circle" background="blue-dark-10">{{ $t('topbar.new-request') }}</IconButton>
      <Notifications></Notifications>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Icon from '@/01-atoms/Icon.vue'
import Button from '@/01-atoms/Button.vue'
import Notifications from '@/02-molecules/Notifications.vue'
import PackageTag from '@/02-molecules/Package-tag.vue'
import { ErrorHandlerKey, MemberServiceKey, PackageServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'

@Options({
  name: 'Topbar',
  components: {
    IconButton,
    Icon,
    Button,
    Notifications,
    PackageTag
  }
})
export default class TopBar extends Vue {
  packageService = inject(PackageServiceKey)
  membersService = inject(MemberServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  beforeMount () {
    this.packageService?.loadCurrentPackage()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })

    this.membersService?.loadCurrentMember()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }
}
</script>
<style lang="scss" scoped>
.topbar {
  @include white-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 30px 12px 46px;
  position: relative;
  z-index: 100;

  @include breakpoint(large down) {
    padding: 12px 20px 12px 12px;
  }

  @include breakpoint($responsive-navigation-breakpoint down) {
    border-radius: 0;
    margin: -15px -15px 15px;
  }
}

.topbar__package {
  @include breakpoint(large down) {
    display: none;
  }
}

.topbar__new-inquiry {
  @include breakpoint(medium down) {
    display: none;
  }
}

.topbar__responsive-package {
  display: none;

  @include breakpoint(large down) {
    display: block;
  }
}

.topbar__greeting {
  @include breakpoint(medium down) {
    display: none;
  }
}
</style>
