
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Separator from '@/02-molecules/Separator.vue'
import Button from '@/01-atoms/Button.vue'
import Icon from '@/01-atoms/Icon.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import calculateContainerHeight from '@/helpers/container-height'
import Notification from '@/02-molecules/Notification.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, NotificationsServiceKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Notifications',
  components: {
    LoadingDots,
    IconButton,
    Separator,
    Button,
    Icon,
    Notification
  }
})
export default class NotificationService extends Vue {
  amountOfNotifications = 3
  isLoading = true

  unseenNotifications = 0

  mounted () {
    this.setContainerHeight()
    this.loadNotifications()
  }

  containerHeight: any = ''
  setContainerHeight () {
    this.containerHeight = calculateContainerHeight(this.$refs['notifications-container'], 180)
  }

  notificationsService = inject(NotificationsServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  loadNotifications () {
    this.notificationsService?.loadNotificationsPreview(1, this.amountOfNotifications)
        .then(() => {
          this.isLoading = false
          this.loadUnseenNotifications()
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  loadUnseenNotifications () {
    this.notificationsService?.notificationsPreview.forEach((notification) => {
      if (!notification.isViewed) {
        this.unseenNotifications = this.unseenNotifications + 1
      }
    })
  }

  blurNotifications (ev: Event) {
    if (this.isLinkInsideOfModal(ev['relatedTarget'])) {
      setTimeout(() => {
        this.hideNotifications()
      }, 250)
    } else {
      this.hideNotifications()
    }
  }

  isLinkInsideOfModal (link: any) {
    return link != null && link.hasAttribute('data-link') && true
  }

  toShowNotifications = false
  toggleNotifications () {
    if (this.toShowNotifications) {
      this.hideNotifications()
    } else {
      this.showNotifications()
    }
  }

  hideNotifications () {
    this.toShowNotifications = false
    enableBodyScroll(this.$refs['notifications-container'])
  }

  showNotifications () {
    this.toShowNotifications = true
    disableBodyScroll(this.$refs['notifications-container'])
  }
}
