<template>
  <IconButton @click="toggleNotifications"
              @blur="blurNotifications"
              :notifications="unseenNotifications"
              background="transparent"
              icon="bell"
              round />
  <div :class="[toShowNotifications && 'is-active', isLoading && 'is-loading', 'notifications']"
       ref="notifications-container"
       :style="containerHeight">
    <div v-if="isLoading" class="mb-m notifications__placeholder">
      <LoadingDots />
    </div>
    <ul v-else class="notifications__list">
      <li class="notifications__list__divider tt-uppercase fs-12"><span class="notifications__list__divider__label">Latest</span></li>
      <li v-for="item in notificationsService.notificationsPreview" v-bind:key="item.title">
        <Notification :notification="item" />
      </li>
    </ul>
    <div class="notifications__separator"></div>
    <div class="ta-center">
      <Button :to="{name: 'Notifications'}" data-link> {{ $t('global.see-all') }}</Button>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Separator from '@/02-molecules/Separator.vue'
import Button from '@/01-atoms/Button.vue'
import Icon from '@/01-atoms/Icon.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import calculateContainerHeight from '@/helpers/container-height'
import Notification from '@/02-molecules/Notification.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, NotificationsServiceKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Notifications',
  components: {
    LoadingDots,
    IconButton,
    Separator,
    Button,
    Icon,
    Notification
  }
})
export default class NotificationService extends Vue {
  amountOfNotifications = 3
  isLoading = true

  unseenNotifications = 0

  mounted () {
    this.setContainerHeight()
    this.loadNotifications()
  }

  containerHeight: any = ''
  setContainerHeight () {
    this.containerHeight = calculateContainerHeight(this.$refs['notifications-container'], 180)
  }

  notificationsService = inject(NotificationsServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  loadNotifications () {
    this.notificationsService?.loadNotificationsPreview(1, this.amountOfNotifications)
        .then(() => {
          this.isLoading = false
          this.loadUnseenNotifications()
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  loadUnseenNotifications () {
    this.notificationsService?.notificationsPreview.forEach((notification) => {
      if (!notification.isViewed) {
        this.unseenNotifications = this.unseenNotifications + 1
      }
    })
  }

  blurNotifications (ev: Event) {
    if (this.isLinkInsideOfModal(ev['relatedTarget'])) {
      setTimeout(() => {
        this.hideNotifications()
      }, 250)
    } else {
      this.hideNotifications()
    }
  }

  isLinkInsideOfModal (link: any) {
    return link != null && link.hasAttribute('data-link') && true
  }

  toShowNotifications = false
  toggleNotifications () {
    if (this.toShowNotifications) {
      this.hideNotifications()
    } else {
      this.showNotifications()
    }
  }

  hideNotifications () {
    this.toShowNotifications = false
    enableBodyScroll(this.$refs['notifications-container'])
  }

  showNotifications () {
    this.toShowNotifications = true
    disableBodyScroll(this.$refs['notifications-container'])
  }
}
</script>
<style lang="scss" scoped>

.notifications {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 $grey-dark-20;
  display: none;
  flex-direction: column;
  max-height: 420px;
  padding: 16px 27px;
  position: absolute;
  top: calc(100% + 30px);
  right: 0;
  width: 450px;
  z-index: 1;

  &.is-loading {
    opacity: 1;
  }

  @include breakpoint(xmedium down) {
    height: calc(100vh - 180px);
    max-height: unset;
    right: $small;
    left: $small;
    width: unset;
  }

  @include breakpoint($responsive-navigation-breakpoint down) {
    top: calc(100% + 15px);
  }

  &::after {
    content: '';
    position: absolute;
    right: 32px;
    top: -20px;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 20px solid white;
    height: 0;
    width: 0;

    @include breakpoint($responsive-navigation-breakpoint down) {
      right: 10px;
    }
  }

  &.is-active {
    display: flex;
  }
}

.notifications__list {
  list-style: none;
  padding-left: 4px;
  overflow-y: scroll;

  // hack to create space for spacebar
  margin-right: -15px;
  padding-right: 15px;
}

.notifications__list__divider {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 25px;

  &::after {
    content: '';
    display: inline-block;
    background-color: $blue-dark-10;
    height: 2px;
    margin-left: 10px;
    position: relative;
    width: 100%;
  }
}

.notifications__list__divider__label {
  flex-shrink: 0;
}
.notifications__separator {
  display: block;
  background: $blue-dark-10;
  height: 2px;
  margin-bottom: 15px;
  width: 100%;
}

.notifications__list__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.notifications__list__item__title {
  max-width: 230px;
}

.notifications__list__item__meta {
  flex-grow: 1;
}

.notifications__placeholder {
  margin-top: 30px;
  min-height: 50px;
}
</style>
