
import { Options, Vue } from 'vue-class-component'
import TopBar from '@/03-organisms/Topbar.vue'
import Navigation from '@/03-organisms/Navigation.vue'
import checkMediaQuery from '@/helpers/media-query'
import variables from '@/scss/js-variables.scss'

@Options({
  name: 'Logged-in',
  components: {
    TopBar,
    Navigation
  }
})
export default class Simple extends Vue {
  showResponsiveNavigation = true

  created () {
    this.$watch(() => this.$route.params, () => {
      this.checkForChat()
    })
  }

  mounted () {
    this.checkForChat()
  }

  checkForChat () {
    this.showResponsiveNavigation = !this.showResponsiveChat()
  }

  showResponsiveChat () {
    return this.$route.name === 'Chat' && checkMediaQuery(`(max-width: ${variables.inboxBreakpoint})`)
  }
}
