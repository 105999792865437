// TODO: update Vue instance on media query change
const isMatching = (mediaQuery: any) => {
  return mediaQuery.matches
}

/**
 *
 * @param breakpoint - '(max-width: 1024px)'
 */
const checkMediaQuery = (breakpoint: string) => {
  const mediaQuery = window.matchMedia(breakpoint)
  mediaQuery.addEventListener('change', isMatching)
  return isMatching(mediaQuery)
}

export default checkMediaQuery
