
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Icon from '@/01-atoms/Icon.vue'
import Button from '@/01-atoms/Button.vue'
import Notifications from '@/02-molecules/Notifications.vue'
import PackageTag from '@/02-molecules/Package-tag.vue'
import { ErrorHandlerKey, MemberServiceKey, PackageServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'

@Options({
  name: 'Topbar',
  components: {
    IconButton,
    Icon,
    Button,
    Notifications,
    PackageTag
  }
})
export default class TopBar extends Vue {
  packageService = inject(PackageServiceKey)
  membersService = inject(MemberServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  beforeMount () {
    this.packageService?.loadCurrentPackage()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })

    this.membersService?.loadCurrentMember()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }
}
