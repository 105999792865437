
import { Options, Vue, prop } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import { NotificationsServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'

class Props {
  notification = prop({
    type: Object,
    required: true,
    default: {
      id: '123e4567-e89b-12d3-a456-426614174000',
      createdAt: '2019-12-12 23:12:21',
      title: 'Notification Title',
      description: 'Notification Description',
      isViewed: false,
      type: 'inquiries',
      action: 'inquiry_upload',
      actionEntryId: '32812324231'
    }
  })
}

@Options({
  name: 'Notification',
  components: {
    Icon
  }
})
export default class Notification extends Vue.with(Props) {
  notificationService = inject(NotificationsServiceKey)

  clickNotification (notification: any, ev: Event) {
    if (notification.actionEntryId) {
      ev.preventDefault()
      this.updateNotification(notification)
    }
  }

  updateNotification (notification: any) {
    this.notificationService?.updateNotification(notification.id)
    this.forwardNotification(notification)
  }

  forwardNotification (notification: any) {
    this.$router.push(this.notificationFactory(notification)['route'])
  }

  notificationFactory (item: any) {
    const notification = {
      icon: 'eye',
      route: { name: 'Settings', params: { id: '' } }
    }

    switch (item.type) {
      case 'inquiries':
        notification['route'] = { name: 'Inquiry', params: { id: item['actionEntryId'] } }
        notification['icon'] = 'upload'
        break
      case 'documents':
        if (item.action === 'payment') {
          notification['route'] = { name: 'Document payment', params: { id: item['actionEntryId'] } }
          notification['icon'] = 'credit-card'
        } else if (item.action === 'document_upload') {
          notification['route'] = { name: 'Document upload', params: { id: item['actionEntryId'] } }
          notification['icon'] = 'upload'
        } else {
          notification['route'] = { name: 'Document', params: { id: item['actionEntryId'] } }
          notification['icon'] = 'file-text'
        }
        break
      case 'family':
        notification['route'] = { name: 'Family member', params: { id: item['actionEntryId'] } }
        notification['icon'] = 'user'
        break
      case 'settings':
        notification['route'] = { name: 'Settings', params: { id: '' } }
        notification['icon'] = 'settings'
        break
      case 'support':
        notification['route'] = { name: 'Chat', params: { id: item['actionEntryId'] } }
        notification['icon'] = 'message-circle'
        break
      default:
        notification['icon'] = 'alert-circle'
    }

    return notification
  }
}
