<template>
  <div class="logged-in">
    <Navigation v-if="showResponsiveNavigation"></Navigation>
    <main :class="`logged-in__main ${showResponsiveNavigation || 'no-navigation'}`">
      <TopBar class="mb-l"></TopBar>
      <router-view />
    </main>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import TopBar from '@/03-organisms/Topbar.vue'
import Navigation from '@/03-organisms/Navigation.vue'
import checkMediaQuery from '@/helpers/media-query'
import variables from '@/scss/js-variables.scss'

@Options({
  name: 'Logged-in',
  components: {
    TopBar,
    Navigation
  }
})
export default class Simple extends Vue {
  showResponsiveNavigation = true

  created () {
    this.$watch(() => this.$route.params, () => {
      this.checkForChat()
    })
  }

  mounted () {
    this.checkForChat()
  }

  checkForChat () {
    this.showResponsiveNavigation = !this.showResponsiveChat()
  }

  showResponsiveChat () {
    return this.$route.name === 'Chat' && checkMediaQuery(`(max-width: ${variables.inboxBreakpoint})`)
  }
}
</script>
<style lang="scss" scoped>

.logged-in {
  display: flex;
  max-width: 2000px;
  margin: 0 auto;
}

.logged-in__main {
  flex-grow: 1;
  padding: $xmedium 2vw 50px;

  @include breakpoint($responsive-navigation-breakpoint down) {
    padding: 15px;
    padding-bottom: 85px;
    width: 100%;
  }

  &.no-navigation {
    padding-bottom: 0;
  }
}
</style>
