<template>
  <component
    :is="notification.actionEntryId ? 'a' : 'div'"
    :href="notification.actionEntryId ? '#' : ''"
    data-link
    :class="[notification.actionEntryId && 'is-clickable', 'notification']"
    @click="clickNotification(notification, $event)">
    <Icon :notifications="notification.isViewed ? 0 : 1"
          color="blue-dark"
          :icon="notificationFactory(notification)['icon']"
          class="mr-m notification__icon" />
    <div class="notification__meta">
      <span class="fs-16 fw-700 d-b notification__title blue-dark">{{ notification.title }}</span>
      <span class="fs-12 grey-dark">{{ notification.description }}</span>
    </div>
    <div class="notification__date fs-14 grey-dark">{{ formatDate(notification.createdAt)}}</div>
  </component>
</template>
<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import { NotificationsServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'

class Props {
  notification = prop({
    type: Object,
    required: true,
    default: {
      id: '123e4567-e89b-12d3-a456-426614174000',
      createdAt: '2019-12-12 23:12:21',
      title: 'Notification Title',
      description: 'Notification Description',
      isViewed: false,
      type: 'inquiries',
      action: 'inquiry_upload',
      actionEntryId: '32812324231'
    }
  })
}

@Options({
  name: 'Notification',
  components: {
    Icon
  }
})
export default class Notification extends Vue.with(Props) {
  notificationService = inject(NotificationsServiceKey)

  clickNotification (notification: any, ev: Event) {
    if (notification.actionEntryId) {
      ev.preventDefault()
      this.updateNotification(notification)
    }
  }

  updateNotification (notification: any) {
    this.notificationService?.updateNotification(notification.id)
    this.forwardNotification(notification)
  }

  forwardNotification (notification: any) {
    this.$router.push(this.notificationFactory(notification)['route'])
  }

  notificationFactory (item: any) {
    const notification = {
      icon: 'eye',
      route: { name: 'Settings', params: { id: '' } }
    }

    switch (item.type) {
      case 'inquiries':
        notification['route'] = { name: 'Inquiry', params: { id: item['actionEntryId'] } }
        notification['icon'] = 'upload'
        break
      case 'documents':
        if (item.action === 'payment') {
          notification['route'] = { name: 'Document payment', params: { id: item['actionEntryId'] } }
          notification['icon'] = 'credit-card'
        } else if (item.action === 'document_upload') {
          notification['route'] = { name: 'Document upload', params: { id: item['actionEntryId'] } }
          notification['icon'] = 'upload'
        } else {
          notification['route'] = { name: 'Document', params: { id: item['actionEntryId'] } }
          notification['icon'] = 'file-text'
        }
        break
      case 'family':
        notification['route'] = { name: 'Family member', params: { id: item['actionEntryId'] } }
        notification['icon'] = 'user'
        break
      case 'settings':
        notification['route'] = { name: 'Settings', params: { id: '' } }
        notification['icon'] = 'settings'
        break
      case 'support':
        notification['route'] = { name: 'Chat', params: { id: item['actionEntryId'] } }
        notification['icon'] = 'message-circle'
        break
      default:
        notification['icon'] = 'alert-circle'
    }

    return notification
  }
}
</script>
<style lang="scss" scoped>
.notification {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;

  &.is-clickable:hover {
    cursor: pointer;
  }
}

.notification__title {
  max-width: 230px;
}

.notification__meta {
  flex-grow: 1;
}

.notification__icon {
  flex-shrink: 0;
}
</style>
