<template>
  <nav class="responsive-navigation">
    <router-link class="responsive-navigation__link" :to="{name: 'Dashboard'}" active-class="is-active">
      <span @click="closeNavigationOverlay" class="fs-12 responsive-navigation__item">
        <Icon class="responsive-navigation__item__icon mb-s" icon="home" />
        <span class="responsive-navigation__label">
          {{ $t('navigation.home') }}
        </span>
      </span>
    </router-link>
    <router-link class="responsive-navigation__link" :to="{name: 'Inquiries'}" active-class="is-active">
      <span @click="closeNavigationOverlay" class="fs-12 responsive-navigation__item">
        <Icon notifications="1" class="responsive-navigation__item__icon mb-s" icon="upload" />
        <span class="responsive-navigation__label">
          {{ $t('navigation.inquiries') }}
        </span>
      </span>
    </router-link>
    <router-link class="responsive-navigation__link" :to="{name: 'Family'}" active-class="is-active">
      <span @click="closeNavigationOverlay" class="fs-12 responsive-navigation__item">
        <Icon class="responsive-navigation__item__icon mb-s" icon="users" />
        <span class="responsive-navigation__label">
          {{ $t('navigation.family') }}
        </span>
      </span>
    </router-link>
    <router-link class="responsive-navigation__link" :to="{name: 'Documents'}" active-class="is-active">
      <span @click="closeNavigationOverlay" class="fs-12 responsive-navigation__item">
        <Icon notifications="1" class="responsive-navigation__item__icon mb-s" icon="file-text" />
        <span class="responsive-navigation__label">
          {{ $t('navigation.documents') }}
        </span>
      </span>
    </router-link>
    <a href="#" @click="toggleNavigationOverlay" class="responsive-navigation__link">
      <span class="fs-12 responsive-navigation__item">
        <Icon :icon="moreMenuIcon" class="responsive-navigation__item__icon mb-s" />
        <span class="responsive-navigation__label">
          {{ $t('navigation.more') }}
        </span>
      </span>
    </a>
  </nav>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  moreMenuIcon!: string
}

@Options({
  name: 'Responsive-navigation',
  components: {
    Icon
  },
  emits: ['toggle-navigation-overlay']
})
export default class ResponsiveNavigation extends Vue.with(Props) {
  closeNavigationOverlay () {
    if (this.moreMenuIcon === 'x') {
      this.toggleNavigationOverlay()
    }
  }

  toggleNavigationOverlay () {
    this.$emit('toggle-navigation-overlay')
  }
}
</script>
<style lang="scss" scoped>
.responsive-navigation {
  @include z-index('responsive-menu');
  background-color: $white;
  box-shadow: 1px 2px 10px -2px transparentize($grey-dark, 0.5);
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 15px 0;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  @include breakpoint($responsive-navigation-breakpoint down) {
    display: flex;
  }
}

.responsive-navigation__item__icon {
  color: $blue-dark;
  flex-shrink: 0;
  margin-right: auto;
  margin-left: auto;

  .is-active > .responsive-navigation__item > & {
    background-color: $blue-dark;
    border-radius: 50%;
    color: white;
    padding: 0 4px;
  }
}

.responsive-navigation__link {
  @include focus;
  background-color: transparent;
  color: $grey-dark;
  height: 50px;
  padding: 0;
  position: relative;
  width: 50px;
}

.responsive-navigation__label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>
