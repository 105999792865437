
import { Options, Vue } from 'vue-class-component'
import Logo from '@/assets/logo-minimal.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Icon from '@/01-atoms/Icon.vue'
import ResponsiveNavigation from '@/03-organisms/Responsive-navigation.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { logout } from '@/api/user/user-api'
import { ErrorHandlerKey, NotificationsServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'

@Options({
  name: 'Navigation',
  components: {
    Logo,
    IconButton,
    Icon,
    ResponsiveNavigation
  }
})

export default class Navigation extends Vue {
  showResponsiveNavigationOverlay = false
  moreMenuIcon = 'menu'

  //for translation purposes only
  switchTo = ''

  notificationsService = inject(NotificationsServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)
  beforeMount () {
    this.notificationsService?.loadNotifications(1, 20)
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })

    if (this.$i18n.locale === 'en') {
      this.switchTo = 'Ids'
    } else {
      this.switchTo = 'english'
    }
  }

  switchLanguage () {
    if (this.$i18n.locale === 'en') {
      this.$i18n.locale = 'tlh'
      this.switchTo = 'english'
    } else {
      this.$i18n.locale = 'en'
      this.switchTo = 'ids'
    }
    this.setHtmlLocale()
  }

  setHtmlLocale () {
    const htmlElement = window['document'].querySelector('html')!
    htmlElement.setAttribute('lang', this.$i18n.locale)
  }
  //end for translation purposes only

  toggleNavigationOverlay () {
    if (this.showResponsiveNavigationOverlay) {
      this.closeNavigationOverlay()
    } else {
      this.openNavigationOverlay()
    }
  }

  openNavigationOverlay () {
    this.moreMenuIcon = 'x'
    disableBodyScroll(this.$refs.navigation)
    this.showResponsiveNavigationOverlay = true
  }

  closeNavigationOverlay () {
    this.moreMenuIcon = 'menu'
    enableBodyScroll(this.$refs.navigation)
    this.showResponsiveNavigationOverlay = false
  }

  clickLogout () {
    this.closeNavigationOverlay()
    logout()
  }

  getUnseens (notifications: any, type: string) {
    let number = 0
    this.notificationsService?.notifications.forEach((notification) => {
      if (!notification.isViewed && notification.type === type) {
        number += 1
      }
    })
    return number
  }
}
