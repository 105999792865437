<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="114" height="31" viewBox="0 0 114 31">
    <g fill="none" transform="translate(.462)">
      <g transform="translate(57.73)">
        <path fill="#00A9E0" d="M40.8702467,16.4661829 L27.1593614,0.459216255 L27.1593614,20.9271408 C24.5352685,22.3047896 19.6806967,24.6664731 19.3526851,24.7976778 C17.9750363,25.5193033 16.4661829,25.9129173 14.8917271,25.9129173 C12.2020319,25.9129173 9.70914369,24.7976778 7.80667634,22.8296081 C7.6754717,22.6984035 7.54426705,22.5671988 7.41306241,22.4359942 C7.41306241,22.4359942 7.41306241,22.4359942 7.41306241,22.4359942 C7.28185776,22.3047896 7.15065312,22.1735849 7.0850508,21.9767779 L4.06734398,25.5849057 C4.06734398,25.5849057 4.06734398,25.5849057 4.06734398,25.5849057 C4.26415094,25.7817126 4.46095791,25.9785196 4.65776488,26.1753266 C5.96981132,27.5529753 7.54426705,28.6682148 9.31552975,29.4554427 C11.0867925,30.2426705 12.9892598,30.6362845 14.9573295,30.6362845 C17.2534107,30.6362845 19.4182874,30.1114659 21.4519594,29.0618287 C21.9111756,28.8650218 25.5193033,26.9625544 27.2249637,26.0441219 L27.2249637,30.5706821 L31.7515239,30.5706821 L31.7515239,12.7924528 L40.9358491,23.4856313 L50.0545718,12.7924528 L50.0545718,30.5706821 L54.6467344,30.5706821 L54.6467344,0.393613933 L40.8702467,16.4661829 Z"/>
        <path fill="#0033A0" d="M7.0850508,21.9767779 C5.64179971,20.1399129 4.92017417,17.909434 4.92017417,15.5477504 C4.92017417,12.7924528 5.96981132,10.1683599 7.87227866,8.20029028 C9.77474601,6.23222061 12.2676343,5.18258345 14.9573295,5.18258345 C16.4661829,5.18258345 17.9750363,5.57619739 19.2870827,6.16661829 L19.2870827,6.16661829 C19.549492,6.29782293 19.8119013,6.42902758 20.1399129,6.62583454 L23.2232221,2.9521045 C22.6328012,2.55849057 21.9767779,2.23047896 21.3207547,1.90246734 L21.3207547,1.90246734 L21.3207547,1.90246734 C19.3526851,0.918432511 17.1878084,0.459216255 14.9573295,0.459216255 C12.9892598,0.459216255 11.0867925,0.852830189 9.31552975,1.64005806 C7.54426705,2.42728592 6.03541364,3.47692308 4.65776488,4.92017417 C3.34571843,6.29782293 2.23047896,7.87227866 1.50885341,9.70914369 C0.787227866,11.5460087 0.393613933,13.5796807 0.393613933,15.6133527 C0.393613933,17.6470247 0.787227866,19.6150943 1.50885341,21.4519594 C2.16487663,23.0264151 3.01770682,24.4040639 4.06734398,25.650508 L7.0850508,21.9767779 L7.0850508,21.9767779 Z"/>
      </g>
      <polygon fill="#0033A0" points="40.805 16.466 37.787 20.009 40.805 23.486 49.858 12.792 49.858 23.42 54.45 23.42 54.516 .459"/>
      <path fill="#E10600" d="M27.0937591,0.459216255 L27.0937591,20.7959361 C24.5352685,22.1735849 19.4838897,24.8632801 19.2214804,24.9944848 C17.8438316,25.7161103 16.3349782,25.9785196 14.7605225,25.9785196 C12.0708273,25.9785196 9.57793904,24.8632801 7.6754717,22.8952104 C5.77300435,20.9271408 4.7233672,18.3686502 4.7233672,15.5477504 C4.7233672,12.7924528 5.77300435,10.1683599 7.6754717,8.20029028 C9.57793904,6.23222061 12.0708273,5.18258345 14.7605225,5.18258345 C16.2693759,5.18258345 17.7782293,5.57619739 19.0902758,6.16661829 L19.0902758,6.16661829 L19.0902758,6.16661829 L19.0902758,6.16661829 C19.3526851,6.29782293 19.6150943,6.42902758 19.943106,6.56023222 L23.0264151,2.88650218 C22.4359942,2.49288824 21.779971,2.16487663 21.1239478,1.83686502 L21.1239478,1.83686502 L21.1239478,1.83686502 L21.1239478,1.83686502 C19.1558781,0.852830189 16.9910015,0.393613933 14.8261248,0.393613933 C12.8580552,0.393613933 10.9555878,0.787227866 9.18432511,1.57445573 C7.41306241,2.3616836 5.904209,3.41132075 4.52656023,4.85457184 C3.21451379,6.23222061 2.09927431,7.80667634 1.37764877,9.64354136 C0.656023222,11.4804064 0.262409289,13.5140784 0.262409289,15.5477504 C0.262409289,17.5814224 0.656023222,19.549492 1.37764877,21.386357 C2.09927431,23.2232221 3.21451379,24.7976778 4.52656023,26.1753266 C5.83860668,27.5529753 7.41306241,28.6682148 9.18432511,29.4554427 C10.9555878,30.2426705 12.8580552,30.6362845 14.8261248,30.6362845 C17.1222061,30.6362845 19.2870827,30.1114659 21.3207547,29.0618287 C21.779971,28.8650218 25.5193033,26.8969521 27.1593614,26.0441219 L27.1593614,30.6362845 L31.6859216,30.6362845 L31.6859216,12.8580552 L37.8525399,20.0087083 L40.8702467,16.4661829 L27.0937591,0.459216255 Z"/>
    </g>
  </svg>
</template>
