/**
 * @param {Date} dateCompare
 */
const getTimeAgo = (dateCompare: any) => {
  const hour = 3600000
  const day = 86400000
  const week = 7 * day
  const month = 30 * day
  const year = 365 * day

  const timeConstraints = [
    {
      time: year,
      unit: 'year'
    },
    {
      time: month,
      unit: 'month'
    },
    {
      time: week,
      unit: 'week'
    },
    {
      time: day,
      unit: 'day'
    },
    {
      time: hour,
      unit: 'hour'
    }
  ]

  const dateNow = new Date()
  const dateCompareFormatted = new Date(dateCompare)
  const milliSecondDifference = dateNow.getTime() - dateCompareFormatted.getTime()

  for (const index in timeConstraints) {
    const amount = Math.floor(milliSecondDifference / timeConstraints[index]['time'])
    if (amount >= 1) {
      return {
        amount: amount,
        unit: timeConstraints[index]['unit'],
        index: index
      }
    }
  }

  return {
    index: 4
  }
}

export default getTimeAgo
